<template>
  <a-spin :spinning="loading">
    <div class="role">
      <div>
        <a-button icon="plus-circle" type="primary" ghost @click="handleAddView">新建角色</a-button>
      </div>
      <div class="table">
        <a-table :row-key="(record,index)=>{return index}" :data-source="roleList" :pagination="false">
          <a-table-column key="index" title="序号">
            <template slot-scope="text,record,index">
              {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column key="name" title="名称">
            <template slot-scope="text, record">
              {{ record.name }}
            </template>
          </a-table-column>
          <a-table-column key="setting" title="操作" width="280px">
            <template slot-scope="text, record">
              <a-button type="primary" ghost @click="handleAuthView(record)">
                权限
              </a-button>
              <a-button style="margin-left:10px" type="primary" ghost @click="handleEditView(record)">
                编辑
              </a-button>
              <a-button style="margin-left: 10px" type="danger" ghost @click="handleDeleteView(record)">
                删除
              </a-button>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <add-role-modal ref="addRoleModal" @on-ok="fetchData" />
      <edit-role-modal ref="editRoleModal" @on-ok="fetchData" />
    </div>
  </a-spin>
</template>

<script>
import adminApi from '../../../api/admin'
import AddRoleModal from './component/AddRoleModal.vue'
import EditRoleModal from './component/EditRoleModal.vue'
export default {
  components: { AddRoleModal, EditRoleModal },
  data() {
    return {
      roleList: [],
      loading: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      adminApi.roleList().then(res => {
        this.roleList = res.data
      }).finally(() => { this.loading = false })
    },
    handleAddView() {
      this.$refs.addRoleModal.showAddModal()
    },
    handleEditView(record) {
      this.$refs.editRoleModal.showEditModal(record)
    },
    handleDeleteView(record) {
      const that = this
      this.$confirm({
        title: `确定删除[${record.name}]角色吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          adminApi.delRole(record.id).then(res => {
            that.$message.success(res.msg)
            that.fetchData()
          })
        },
        onCancel() {
        }
      })
    },
    handleAuthView(record) {
      this.$router.push({
        path: '/admin/role-auth',
        query: { id: record.id }
      })
    }
  }
}
</script>
<style scoped>
.role {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.table {
  margin-top: 10px;
}
::v-deep .ant-table-thead > tr > th {
  background-color: #e8f4ff !important;
  color: #1890ff;
}
::v-deep .ant-table-tbody > tr > td {
  background-color: #ffffff !important;
}
</style>
