<template>
  <div class="addUser">
    <a-modal
      title="添加角色"
      :visible="visible"
      :mask-closable="false"
      :confirm-loading="loading"
      ok-text="确认"
      cancel-text="取消"
      :width="400"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:6 }" :wrapper-col="{span: 18}">
          <a-form-item label="名称">
            <a-input id="name" v-model="data.name" placeholder="请输入名称" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import adminApi from '../../../../api/admin'
export default {
  props: {
  },
  data() {
    return {
      visible: false,
      loading: false,
      data: {
        name: ''
      }
    }
  },
  methods: {
    showAddModal() {
      this.visible = true
      this.data = {
        name: ''
      }
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.loading = true
      adminApi.addRole(this.data).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.visible = false
          this.$emit('on-ok')
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
<style scoped>
.content {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing:  border-box;
}
</style>
